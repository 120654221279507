/* new-message style */
.new-message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 80vh;
  overflow: hidden;
  min-height: 70vh;
  position: relative;
}

.message-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.message-content > .message-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.other-message {
  display: flex;
  gap: 4px;
  background-color: #f5f5f5;
  padding: 0.5rem;
  justify-content: flex-start;
  border-radius: 15px;
  width: fit-content;
}
.other-message div {
  display: flex;
  flex-direction: column;
}
.other-message p {
  text-align: left;
  font-size: 14px;
}
.other-message small {
  text-align: end;
  color: #2c4e7a;
  font-size: 8px;
}
.my-message {
  display: flex;
  justify-content: flex-end;
}
.my-message div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #2c4e7a;
  padding: 0.5rem;
  border-radius: 15px;
  color: white;
  width: fit-content;
}
.my-message p {
  text-align: right;
  font-size: 14px;
}
.my-message small {
  text-align: end;
  color: #f5f5f5;
  font-size: 8px;
}
.new-message-form {
  margin: 10px 0px;
  position: sticky;
  bottom: 0;
}
