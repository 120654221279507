.get-starget {
  padding: 20px 50px;
  height: auto;
  background-color: aliceblue;
}
.get-starget > div {
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  padding: 0px 20px;
  background-image: url("../media/cool-background.png");
}
.get-starget-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .get-starget > div {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
  .get-starget-left {
    align-items: center;
  }
  .get-starget-left > h1 {
    text-align: center;
  }
  .get-starget-left > div {
    text-align: center;
  }
}
