/* hero style */
.hero {
  background-image: url("../media/hero.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: contain;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  margin: 0 50px 20px 50px;
}
.hero-content-right {
  position: relative;
}

@media screen and (max-width: 876px) {
  .hero-content {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 2rem;
  }
  .hero-content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .hero-content-left-text {
    text-align: center;
  }
  .hero-content-right > img {
    margin-left: 40px;
    width: 90%;
  }
}

@media screen and (max-width: 687px) {
  .hero-content-right > img {
    margin-left: 50px;
  }
}
