.blogs {
  padding: 20px 50px;
  display: flex;
  gap: 1rem;
}
.blogs-left {
  flex: 1.5;
}
.blogs-right {
  display: grid;
  gap: 0.5rem;
  flex: 0.8;
  max-height: 110vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.blogs-right ul {
  list-style: none;
}
.blogs-right ul li::before {
  content: "➖ " !important;
}
@media screen and (max-width: 676px) {
  .blogs {
    flex-direction: column;
  }
  .blogs-left {
    flex: 1;
  }
  .blogs-right {
    display: flex;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
