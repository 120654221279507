.chat-widget {
  position: fixed;
  bottom: 5px;
  right: 10px;
  z-index: 99999;
}

.chat-toggle {
  background-color: #2c4e7a;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chat-toggle:hover {
  background-color: #265088;
}

.chat-window {
  position: fixed;
  bottom: 60px;
  right: 0rem;
  width: 350px;
  height: 555px;
  border-radius: 10px 0 0 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  overflow: hidden;
}

.chat-window.open {
  transform: translateX(0);
}

.chat-window.closed {
  transform: translateX(100%);
}

.chat-header {
  background-color: #2c4e7a;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-chat {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.chat-body {
  height: 350px;
  overflow-y: auto;
  padding: 20px 15px;
}

.chat-widget .chat-window .chat-body .message {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}

.chat-widget .chat-window .chat-body .message:last-child {
  margin-bottom: 0px;
}

.chat-widget .chat-window .chat-body .message.sent {
  align-self: flex-end;
  background-color: #e8f1ff;
  margin-left: 20%;
}

.chat-widget .chat-window .chat-body .message.received {
  align-self: flex-start;
  background-color: #f0f0f0;
}

.chat-widget .chat-window .chat-body .message .message-text {
  word-break: break-word;
}

.chat-widget .chat-window .chat-body .message .message-date {
  margin-top: 5px;
  font-size: 12px;
  color: #999999;
}

.chat-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-form input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 16px 8px;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 4px;
}

.chat-form button[type="submit"] {
  background-color: #2c4e7a;
  color: #fff;
  border: none;
  outline: none;
  padding: 14px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.chat-form button[type="submit"]:hover {
  background-color: #2c4e7a;
}

.chat-footer {
  padding: 8px;
  text-align: center;
  font-size: small;
}

.dropdown-container {
  position: absolute;
  top: -4px;
  right: -2px;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.dropdown-options {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5px 0px;
  z-index: 999;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: absolute;
  right: 1px;
  top: 24px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
}

.dropdown-options li {
  cursor: pointer;
  padding: 0 5px;
}

.dropdown-options li:hover {
  background-color: #f0f0f0;
}

@media screen and (max-height: 600px) {
  .chat-window.open {
    overflow-y: auto;
  }
}
@media screen and (max-width: 350px) {
  .chat-window.open {
    width: 250px;
  }
}
