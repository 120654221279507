.dashboard {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 80vh;
}
.dashboard-select {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.dashboard-static {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

@media screen and (max-width: 976px) {
  .dashboard-static {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 786px) {
  .dashboard-static {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 687px) {
  .dashboard-static {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .dashboard-static {
    grid-template-columns: repeat(1, 1fr);
  }
}
