@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  text-decoration: none;
  letter-spacing: 0.8px;
  font-weight: 400;
  list-style: none;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
button {
  box-shadow: none !important;
}

.ps-sidebar-container {
  position: fixed !important;
  width: inherit;
}

.divider-content {
  height: 4px;
  background-color: #2c4e7a;
  min-width: 3rem;
}

/* auth styled */
.auth {
  background-image: url("./assets/media/lannd-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-content {
  display: flex;
  justify-content: center;
  margin: 0 50px 20px 50px;
}
/* 404 styled */
.page-404 {
  background-image: url("./assets/media/hero.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: contain;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.ant-segmented-item-label {
  white-space: normal !important;
}
