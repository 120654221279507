.social-media {
  display: flex;
  flex-direction: column;
  padding: 150px;
  align-items: center;
  gap: 2rem;
  background-image: url("../media/bg-2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.social-media-item {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.social-media-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-media-item img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

@media screen and (max-width: 786px) {
  .social-media {
    display: flex;
    flex-direction: column;
    padding: 50px;
    align-items: center;
    gap: 2rem;
  }
}
