/* Partner page */
.partner {
  display: flex;
  flex-direction: column;
  padding: 1rem 50px;
  gap: 1rem;
  background-image: url("../media/path2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.partner-top {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.partner-bottom > img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .partner-top {
    flex-direction: column;
    gap: 1rem;
  }
  .partner-box > h1 {
    text-align: center;
  }
  .partner-box > div {
    text-align: center;
  }
}
