.my-account {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.my-account-content {
  display: flex;
  align-content: center;
  gap: 0.5rem;
}

.account-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media screen and (max-width: 976px) {
  .account-content {
    grid-template-columns: repeat(1, 1fr);
  }
}
