.footer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
}
.footer-left {
  display: flex;
  gap: 1.5rem;
}
.footer-left > a {
  color: #2c4e7a;
  font-size: 0.8rem;
  font-weight: 600;
}
.footer-left > a:hover {
  color: #ccc;
}
.footer-right {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 564px) {
  .footer-content {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
  .footer-left {
    flex-direction: column;
    align-items: center;
  }
}
