.use-app {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding: 20px 50px;
  background-image: url("../media/lannd-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.use-app-grid {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}
.use-app-grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
