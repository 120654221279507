.loader-image {
  animation: scaleAnimation 0.5s infinite alternate;
  -webkit-animation: scaleAnimation 0.5s infinite alternate;
}

.loader-text {
  animation: fadeAnimation 1s infinite;
  -webkit-animation: fadeAnimation 1s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
