.count {
  display: flex;
  flex-direction: column;
  padding: 2rem 50px;
  gap: 1rem;
  background-color: aliceblue;
}
.count-number {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  gap: 2rem;
}
.count-number > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.count-number > div > h1 {
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  color: #2c4e7a;
}
.count-number > div > h3 {
  font-weight: 600;
  text-align: center;
  color: #2c4e7a;
}

@media screen and (max-width: 679px) {
  .count-number {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  }
}
