/* navbar */
.nav-bar-auth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 50px;
}
.nav-bar-auth-link {
  display: flex;
  gap: 0.8rem;
}
.nav-bar-auth-link > a {
  text-decoration: none;
  color: #2c4e7a;
  font-weight: 500;
  padding-bottom: 5px;
  width: fit-content;
}
.nav-bar-auth-link > .auth-navlink:hover {
  border-bottom: 3px solid #2c4e7a;
}
.auth-navlink.active-navlink {
  border-bottom: 3px solid #2c4e7a;
}
.nav-bar-auth-right {
  display: flex;
  gap: 0.8rem;
}
.btn-nav-menu {
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-bar-auth {
    padding-bottom: 2.5rem;
  }
  .nav-bar-auth-link {
    display: none;
  }
  .btn-nav-menu {
    display: block;
  }
  .drawer-auth-link {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .drawer-auth-link > a {
    text-decoration: none;
    color: #2c4e7a;
    font-weight: 500;
    padding-bottom: 5px;
    width: fit-content;
  }
  .drawer-auth-link > .auth-navlink:hover {
    border-bottom: 3px solid #2c4e7a;
  }
  .auth-navlink.active-navlink {
    border-bottom: 3px solid #2c4e7a;
  }
}
@media screen and (max-width: 7686px) {
  .nav-bar-auth {
    padding: 1.5rem 20px;
  }
}

@media screen and (max-width: 575px) {
  .nav-bar-auth-right {
    gap: 0.2rem;
  }
  .nav-bar-auth-right-btn {
    display: none;
  }
}
