.message-all {
  display: grid;
  grid-template-columns: 350px auto;
}
.message-all__sidebar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 2px solid #919191;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.message-all__sidebar-header {
  display: flex;
  padding: 10px 25px 0px 25px;
  flex-direction: column;
}
.message-all__sidebar-content {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.message-all__sidebar-content__item {
  cursor: pointer;
}
.message-all__sidebar-content__item:hover {
  background-color: #b6b6b6;
}
