.logo {
  display: block;
  width: 250px;
  height: auto;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 200px;
  }
}
