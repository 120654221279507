.card-publisher {
  display: flex;
  align-items: center;
  place-items: center;
  gap: 2rem;
  padding: 20px 150px;
}

@media screen and (max-width: 1126px) {
  .card-publisher {
    padding: 20px 100px;
  }
}

@media screen and (max-width: 1026px) {
  .card-publisher {
    padding: 20px 80px;
  }
}
@media screen and (max-width: 968px) {
  .card-publisher {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 876px) {
  .card-publisher {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 828px) {
  .card-publisher {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 734px) {
  .card-publisher {
    flex-direction: column;
    padding: 20px 150px;
  }
}

@media screen and (max-width: 548px) {
  .card-publisher {
    flex-direction: column;
    padding: 20px 80px;
  }
}
