.about-us {
  display: flex;
  flex-direction: column;
  padding: 20px 150px;
  align-items: center;
  gap: 4rem;
  text-align: justify;
}
.about-us-left {
  width: 60%;
}
.about-us-right > h1 {
  border-bottom: 2px solid #2c4e7a;
  padding-bottom: 4px;
}

@media screen and (max-width: 976px) {
  .about-us {
    flex-direction: column;
    padding: 20px 50px;
  }
  .about-us-left {
    width: 100%;
  }
}
