.legal-notice {
  display: flex;
  flex-direction: column;
}
.legal-notice-container {
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  gap: 2rem;
}
.legal-notice-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 10px 100px;
}

@media screen and (max-width: 786px) {
  .legal-notice-container {
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    gap: 2rem;
  }
  .legal-notice-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 10px 50px;
  }
}

@media screen and (max-width: 564px) {
  .legal-notice-container {
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    gap: 2rem;
  }
  .legal-notice-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 10px 30px;
  }
}
