.sidebar-header {
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 24px;
  margin-top: 16px;
}
.sidebar-header > div {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-content: center;
  gap: 0.5rem;
}
.logo-collapsed {
  max-width: 35px;
}
