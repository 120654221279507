.admin-messages {
  padding: 10;
}

.admin-messages__body {
  height: 78vh;
  overflow-y: auto;
  padding: 20px 15px;
}
.admin-messages__body-message {
}
